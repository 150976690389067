<template>
  <div class="custom-container">
    <el-row>
      <div class="flex justify_start">
        <el-page-header :title="lang != 'en' ? '返回' : 'Back'" @back="goBack()"
          :content="lang != 'en' ? '公司编辑' : 'comapny Edit'"></el-page-header>
      </div>
    </el-row>

    <div class="content">
      <p class="dark mt10 mb20">
        <!-- 基础资料 -->
        {{ lang != 'en' ? '基础资料' : 'Base Info' }}
      </p>

      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="90px"
        :label-position="lang == 'en' ? 'top' : 'right'" :key="formKey">
        <el-row :gutter="20">
          <el-col :span="6">
            <!-- label="公司名称"  -->
            <el-form-item prop="companyName" :label="$t('cusDetail.CompanyName')" :rules="[
                {
                  required: true,
                  message: $t('placeholder.plsInput'),
                  trigger: 'blur',
                },
                {
                  min: 2,
                  message: $t('placeholder.M2025'),
                  trigger: 'blur',
                },
              ]">
              <el-input v-model="form.companyName" :placeholder="$t('placeholder.plsInput')" clearable type="text"
                maxlength="300" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- label="网址" -->
            <el-form-item :label="$t('cusDetail.WebAddress')">
              <el-input v-model="form.website" :placeholder="$t('placeholder.plsInput')" clearable type="text"
                maxlength="500" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- label="邮编" -->
            <el-form-item :label="$t('cusDetail.ZipCode')">
              <el-input v-model="form.postcode" :placeholder="$t('placeholder.plsInput')" maxlength="50"
                clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- label="传真" -->
            <el-form-item :label="$t('cusDetail.FaxNumber')">
              <el-input v-model="form.faxNumber" :placeholder="$t('placeholder.plsInput')" maxlength="50"
                clearable></el-input>
            </el-form-item>
          </el-col>
          <!-- 国家 -->
          <el-col :span="6">
            <el-form-item :label="$t('companyAddress.Nation')" prop="country">
              <el-select v-model="form.country" :placeholder="$t('placeholder.plsSel')" @change="nationChange"
                :filter-method="filterCountry" filterable @visible-change="resetCompanyCountryOptions">
                <el-option :label="item.name" :value="item.code" v-for="item in companyCountryOptions"
                  :key="item.code" />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 州 -->
          <el-col :span="6">
            <el-form-item :label="$t('companyAddress.State')" :prop="form.country === 'US' ? 'province' : ''">
              <el-select v-model="form.province" filterable :filter-method="filterSupplier"
                :placeholder="$t('placeholder.plsSel')" @visible-change="resetCompanySelectProvinceOptions"
                :allow-create="form.country !== 'US' && form.country !=='CA'">
                <el-option :label="item.name" :value="item.code" v-for="item in companySelectProvinceOptions"
                  :key="item.code" />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- 市 -->
          <el-col :span="6">
            <el-form-item :label="$t('companyAddress.City')">
              <el-input v-model="form.city" :placeholder="$t('placeholder.plsInput')" />
            </el-form-item>
          </el-col>
          <!-- 详细地址 -->
          <el-col :span="6">
            <!-- label="公司地址" -->
            <el-form-item :label="$t('companyAddress.DetailedAddress')">
              <el-input v-model="form.companyAddress" :placeholder="$t('placeholder.plsInput')" clearable type="text"
                maxlength="300" show-word-limit></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!-- label="备注" -->
            <el-form-item :label="$t('cusDetail.Remark')">
              <el-input v-model="form.remark" :placeholder="$t('placeholder.plsInput')" clearable type="textarea"
                :rows="3" maxlength="500" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="oper-btn">
      <el-button @click="goBack()">
        {{ lang === 'en' ? 'Cancel' : '取消' }}
      </el-button>
      <el-button :loading="loading" @click="save" type="primary">
        {{ lang === 'en' ? 'Confirm' : '确认' }}
      </el-button>
    </div>
  </div>
</template>

<script>
  import { clientEditInstance } from '@/core/interactors/client-supplier/client-edit'
  import { mapGetters } from 'vuex'
  import { OrderListInteractor } from '@/core'
  import { deepCopy } from 'kits'
  export default {
    name: 'CompanyEdit',
    data() {
      return {
        loading: false,
        formKey: 1,
        form: {
          companyId: '', // 公司Id
          companyName: '', // 公司名称
          website: '', // 网址
          postcode: '', // 邮编
          faxNumber: '', // 传真
          remark: '', // 备注
          companyAddress: '', // 公司地址
          country: '', //公司国家
          province: '', //公司州
          city: '', //公司市
        },
        companyCountryOptions: [], //国家下拉框
        initCompanyCountryOptions: [], //国家下拉框初始数据
        companyProvinceOptions: [], //州下拉框
        initCompanyProvinceOptions: [], //州下拉框初始数据
        companySelectProvinceOptions: [],
        rules: {
          country: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'change',
            },
          ],
          province: [
            {
              required: true,
              message: this.$t('placeholder.plsInput'),
              trigger: 'change',
            },
          ],
        },
      }
    },

    computed: {
      ...mapGetters({
        lang: 'settings/language',
      }),
    },

    created() {
      this.form.companyId = this.$route.query.companyId
      this.getDetails(this.form.companyId)
    },
    methods: {
      resetCompanyCountryOptions(val) {
        if (!val) {
          setTimeout(() => {
            console.log('this.initCompanyCountryOptions==', this.initCompanyCountryOptions)
            this.companyCountryOptions = this.initCompanyCountryOptions
          }, 200)
        }
      },
      resetCompanySelectProvinceOptions(val) {
        if (!val) {
          setTimeout(() => {

            this.companySelectProvinceOptions = this.initCompanyProvinceOptions
          }, 200)
        }
      },
      filterCountry(keyword) {
        let val = keyword.trim().toLowerCase()
        if (val) {
          this.companyCountryOptions =
            this.initCompanyCountryOptions.filter((item, index) => {
              return item.name.toLowerCase().indexOf(val) > -1
            })
        } else {
          this.companyCountryOptions = this.initCompanyCountryOptions
        }
      },
      filterSupplier(keyword) {
        let val = keyword.trim().toLowerCase();
        if (val) {
          this.companySelectProvinceOptions =
            this.companyProvinceOptions.filter((item, index) => {
              return item.name.toLowerCase().indexOf(val) > -1;
            })
        } else {
          this.companySelectProvinceOptions = this.companyProvinceOptions
        }
      },
      // 获取国家
      async getNationFn() {
        let data = { level: 0 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          this.companyCountryOptions = res.data
          this.initCompanyCountryOptions = deepCopy(res.data)
        }
      },
      // 根据国家获取州
      async getStateFn(val) {
        let data = { parentId: val, level: 1 }
        const res = await OrderListInteractor.listCountryApi(data)
        if (res?.code === '000000') {
          this.companyProvinceOptions = res.data
          this.companySelectProvinceOptions = res.data
          this.initCompanyProvinceOptions = deepCopy(res.data)
        } else {
          this.companyProvinceOptions = []
          this.companySelectProvinceOptions = []
        }
      },
      // 选择国家
      nationChange(val) {
        this.resetCompanyCountryOptions()
        this.formKey++
        this.form.province = ''
        // this.postcode = ''
        let idObj = this.companyCountryOptions.filter(
          (item) => item.code === val
        )?.[0]
        this.getStateFn(idObj.id)
      },
      // 初始化编辑数据
      async getDetails(id) {
        await this.getNationFn()
        const _res = await clientEditInstance.getDetailForEdit(id)
        if (_res && _res.code === '000000') {
          const _data = _res.data
          let idObj = this.companyCountryOptions.filter(
            (item) => item.code === _data.country
          )?.[0]
          idObj && this.getStateFn(idObj.id)
          this.form.country = _data.country ?? '' // 国家
          this.form.province = _data.province ?? '' // 州
          this.form.city = _data.city ?? '' // 市
          this.form.companyName = _data.companyName // 公司名称
          this.form.website = _data.website // 网址
          this.form.companyAddress = _data.companyAddress // 公司地址
          this.form.postcode = _data.postcode // 邮编
          this.form.faxNumber = _data.faxNumber // 传真
          this.form.remark = _data.remark // 备注
        }
      },

      // 保存
      save() {
        this.$refs['ruleForm'].validate(async (valid) => {
          if (valid) {
            const params = { ...this.form }
            this.loading = true
            const _res = await clientEditInstance.companyUpdatePO(params)
            this.loading = false
            if (_res && _res.code === '000000') {
              this.$message.success(
                this.lang === 'en' ? 'Operate successfully' : '保存成功'
              )
              this.$router.push('/n-customer-manage/cus-center')
            }
          }
        })
      },

      // 返回上一页
      goBack() {
        this.$router.go(-1)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    padding: 0 10px 0;
    height: calc(100vh - 220px);
  }

  .oper-btn {
    position: sticky;
    bottom: 0;
    text-align: center;
    background: white;
    padding: 10px 0;
    z-index: 2;
  }

  ::v-deep .required-icon {
    .el-form-item__label {
      &::before {
        content: '*';
        color: #ff4d4f;
        margin-right: 4px;
      }
    }
  }

  .el-select {
    width: 100%;
  }
</style>