import {
  getDetailForEdit,
  companyUpdatePO,
  getEditDetail,
  clientUpdatePO,
  checkEmail,
} from '@/core/services/api/client-supplier/client-edit'

class clientEdit {
  static getInstance() {
    return this._instance
  }

  /**
   * 客户管理-客户公司编辑回显
   * @param { companyId } 公司id
   */
  getDetailForEdit(companyId) {
    return getDetailForEdit({ companyId: companyId })
  }

  /**
   * 客户管理-客户公司编辑保存
   * @param { params }
   */
  companyUpdatePO(params) {
    return companyUpdatePO(params)
  }

  /**
   * 客户管理-客户编辑回显
   * @param { infoId }
   */
  getEditClientDetail(infoId) {
    return getEditDetail({ infoId: infoId })
  }

  /**
   * 客户管理-客户编辑保存
   * @param { params }
   */
  clientUpdatePO(params) {
    return clientUpdatePO(params)
  }

  /**
   * 客户管理-校验邮箱是否重复
   * @param { email }
   */
  checkEmail(email) {
    return checkEmail({ email: email })
  }
}

clientEdit._instance = new clientEdit()

export const clientEditInstance = clientEdit.getInstance()
