import request from '@/core/services/request'

// 客户管理-客户公司编辑回显
export function getDetailForEdit(data) {
  return request({
    url: '/merchants/company/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 客户管理-客户公司编辑保存
export function companyUpdatePO(data) {
  return request({
    url: '/merchants/company/updatePO',
    method: 'post',
    data,
  })
}

// 客户管理-客户编辑回显
export function getEditDetail(data) {
  return request({
    url: '/merchants/info/getEditDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 客户管理-更新实体
export function clientUpdatePO(data) {
  return request({
    url: '/merchants/info/updatePO',
    method: 'post',
    data,
  })
}

// 客户管理-校验邮箱是否重复
export function checkEmail(data) {
  return request({
    url: '/merchants/info/checkEmail',
    method: 'post',
    data,
  })
}
